import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { useIntl } from 'gatsby-plugin-intl';
import { customContainer } from '@utils/twin-macro';

import Layout from '@layouts/';
import SEO from '@components/seo.jsx';
import Posts from '@components/blog/posts';
import Text from '@components/text';
import Nav from '@components/blog/nav';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    w-320 pt-40 px-10 pb-20 text-center
    sm:w-460
    md:w-auto md:pb-55 md:text-left
    lg:px-0 
  `}
`;

const Tag = ({ pageContext, data }) => {
  const intl = useIntl();
  const { humanPageNumber, nextPagePath, previousPagePath } = pageContext;

  const prevPage = previousPagePath ? `/tag${previousPagePath}` : '';
  const nextPage = nextPagePath ? `/tag${nextPagePath}` : '';

  return (
    <Layout
      langInfo={intl.locale === 'en'}
      switchPath={[
        `/tag${humanPageNumber !== 1 ? `/${humanPageNumber}` : ''}`,
        `/en/tag${humanPageNumber !== 1 ? `/${humanPageNumber}` : ''}`,
      ]}
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'meta tags.title.blog' })}
        description={intl.formatMessage({ id: 'meta tags.description.blog' })}
      />
      <Wrapper>
        <Text fontFamily="bebasNeue" fontSizes={58} fontWeight={300} lineHeight={1.09} letterSpacing={72}>
          {data.sanityTags[`name_${intl.locale}`]}
        </Text>
      </Wrapper>
      <Posts posts={data.allSanityPost.nodes} locale={intl.locale} noRecommended />
      <Nav nextPage={nextPage} prevPage={prevPage} locale={intl.locale} />
    </Layout>
  );
};

export const query = graphql`
  query($skip: Int!, $limit: Int!, $id: String) {
    sanityTags(id: {eq: $id}) {
      name_en
      name_pl
      slug_en {
        current
      }
      slug_pl {
        current
      }
    }
    allSanityPost(
      filter: {post_tags: {elemMatch: {id: {eq: $id}}}}
      sort: {fields: date, order: DESC}
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        title_pl
        title_en
        _rawPostPreviewPl
        _rawPostPreviewEn
        slug_en {
          current
        }
        slug_pl {
          current
        }
        author {
          name
        }
        date
        mask_big {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
        post_tags {
          slug_en {
            current
          }
          slug_pl {
            current
          }
          name_pl
          name_en
        }
      }
    }
  }
`;

Tag.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  pageContext: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
};

export default Tag;
